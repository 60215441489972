import React, { Component } from 'react';
import { WellbeingDashboardContainer, YourWellbeing, WellbeingCategories, DetailsContainer, TakeAssessmentButton } from './styles';
import PropTypes from "prop-types";
import { withTranslation } from 'react-i18next';

const categories = [
  { title: "Health", color: "#7164C4", questionsCount: 7 },
  { title: "Lifestyle", color: "#C464AC", questionsCount: 10 },
  { title: "Fitness", color: "#4798D9", questionsCount: 10 },
  { title: "Nutrition", color: "#8BBD53", questionsCount: 10 },
  { title: "Stress", color: "#E48D8B", questionsCount: 10 },
  { title: "Workplace", color: "#F4D463", questionsCount: 10 }
];
class WellbeingDashboard extends Component {

  redirection = () => {
    const { history } = this.props;
    history.push("/wellness-assessment/health")
  }

  render() {
    return (
      <WellbeingDashboardContainer>
        <YourWellbeing>
          <div>{this.props.t("Health risk Assessment")}</div>
          <p>{this.props.t("Please select the answers that best apply to you. Your results are strictly confidential.")}</p>
          <img src={"/public/images/WBA.png"} alt="wellbeing" />
          <div>{this.props.t("Your assessment should take fewer than 15 minutes to complete.")}</div>
        </YourWellbeing>
        <WellbeingCategories>
          {
            categories.map((data, index) => (<DetailsContainer key={index} bgColor={data.color}>
              <div>
                <div>
                  <div />
                  <span>{this.props.t(data.title)}</span>
                </div>
                <div>
                  {`${data.questionsCount} ${this.props.t("questions")}`}
                </div>
              </div>
            </DetailsContainer>
            ))
          }
          <TakeAssessmentButton onClick={()=>this.redirection()}>{this.props.t("take assessment now")}</TakeAssessmentButton>
        </WellbeingCategories>
      </WellbeingDashboardContainer>
    );
  }

}
WellbeingDashboard.propTypes = {
  history: PropTypes.object,
  t: PropTypes.func
}
export default ((withTranslation())(WellbeingDashboard));