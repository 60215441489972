/* eslint-disable no-undef*/
import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Layout, FormContainer } from './styles';
import { connect } from 'react-redux';

import { MedicalForm, LifestyleForm, FitnessForm, NutritionForm, StressForm, WorkplaceForm } from '../../components';
import {getWellnessAssessment, fetchHRAQuestionAnswers} from "../../redux/actions";
import WellbeingDashboard from "./WellbeingDashboard";
import {HeaderTitle, SideMenu, MainContainer, CategoriesContainer, ImageFiller, QuestionsContainer} from './WellbeingDashboard/styles';
import HraGraphResults from '../../components/HraGraphResults';
import isNull from 'lodash/isNull';
import { Waiting } from '../../components';
import { withTranslation } from 'react-i18next';

class WellnessAssessment extends Component {
  constructor(props) {
    super(props);
    this.state={
      selectedCategoryTitle: 'health'
    }
  }
  componentDidMount() {
    const {wellnessAssessment, history, location, fetchHRAQuestionAnswers} = this.props;
    fetchHRAQuestionAnswers();
    if(location.pathname !== '/wellness-assessment'){
      history.push('/wellness-assessment');
    }
    if(!wellnessAssessment) getWellnessAssessment();
    if(!_.isNull(wellnessAssessment) && !_.isUndefined(wellnessAssessment)){
      wellnessAssessment.is_wba_taken && history.push('/portal')
    }
  }

  navigateCategory = (type) => {
    const{history} = this.props;
    history.push(`/wellness-assessment/${type}`)
  }
  
  renderSideMenu = (categories) => categories.map((data, index) =>
    <CategoriesContainer disabled={(!_.isUndefined(this.props.stressData) && this.props.stressData.length === 10) ? 0 : 1} key={index} onClick={() => this.navigateCategory(data.title)} active={data.title === this.state.selectedCategoryTitle ? 1 : 0} bgColor={data.color}>
      <div>
        <div>
          <ImageFiller bgColor={data.color} width={data.title === 'lifestyle' ? 1 : 0}>
            <img src={data.imgPath} alt={data.title}/>
          </ImageFiller>
          <span>{this.props.t(data.title)}</span>
        </div>
        <div>
          {`${data.questionsCount} ${this.props.t("questions")}`}
        </div>
      </div>
    </CategoriesContainer>
  );

  componentDidUpdate(prevProps) {
    const{location:{pathname}} = this.props;
    if(pathname !== '/wellness-assessment' && pathname !== prevProps.location.pathname){
      this.setState({selectedCategoryTitle: pathname.split('/')[2]}, ()=>{ window.scrollTo(0,0);})
    }
  }

  medicalCategory = () => {
    const { hraQuestionsAnswersData, history } = this.props;
    return (<MedicalForm hraQuestionsAnswersData={hraQuestionsAnswersData} history={history}/>)
  }

  lifeStyleCategory = () => {
    const { hraQuestionsAnswersData, history } = this.props;
    return (<LifestyleForm hraQuestionsAnswersData={hraQuestionsAnswersData} history={history}/>)
  }

  fitnessCategory = () => {
    const { hraQuestionsAnswersData, history } = this.props;
    return (<FitnessForm hraQuestionsAnswersData={hraQuestionsAnswersData} history={history}/>)
  }

  nutritionCategory = () => {
    const { hraQuestionsAnswersData, history } = this.props;
    return (<NutritionForm hraQuestionsAnswersData={hraQuestionsAnswersData} history={history}/>)
  }

  stressCategory = () => {
    const { hraQuestionsAnswersData, history } = this.props;
    return (<StressForm hraQuestionsAnswersData={hraQuestionsAnswersData} history={history}/>)
  }

  workplaceCategory = () => {
    const { hraQuestionsAnswersData, history } = this.props;
    return (<WorkplaceForm hraQuestionsAnswersData={hraQuestionsAnswersData} history={history}/>)
  }

  render() {
    const {location, hraQuestionsAnswersData} = this.props;
    const hideSideMenu = location.pathname === ("/wellness-assessment") || location.pathname === ("/wellness-assessment/score");
    const assessmentCompleted = location.pathname === ("/wellness-assessment/score");
    if(!hraQuestionsAnswersData ||  isNull(hraQuestionsAnswersData) ) {
      return <Waiting />
    }
    const categories = [
      {title: "health", color: "#7164C4", imgPath: '/public/images/HomePageV2/health.png', questionsCount: hraQuestionsAnswersData['medical'].length},
      {title: "lifestyle", color: "#C464AC", imgPath: '/public/images/HomePageV2/lifestyle.png', questionsCount: hraQuestionsAnswersData['lifestyle'].length},
      {title: "fitness", color: "#4798D9", imgPath: '/public/images/HomePageV2/fitness.png', questionsCount: hraQuestionsAnswersData['fitness'].length},
      {title: "nutrition", color: "#8BBD53", imgPath: '/public/images/HomePageV2/apple.png', questionsCount: hraQuestionsAnswersData['nutrition'].length},
      {title: "stress", color: "#E48D8B", imgPath: '/public/images/HomePageV2/stress.png', questionsCount: hraQuestionsAnswersData['stress'].length},
      {title: "workplace", color: "#F4D463", imgPath: '/public/images/HomePageV2/workplace.png', questionsCount: hraQuestionsAnswersData['workplace'].length}
    ];
    return (
      <Layout>
        <div>
          <FormContainer>
            <HeaderTitle>
              <div>{this.props.t("Health Risk Assessment")}</div>
              <div>{assessmentCompleted ? this.props.t("Your assessment is complete") : this.props.t("Take your assessment now")}</div>
            </HeaderTitle>
            <MainContainer>
              <SideMenu>
                { hideSideMenu ? null : this.renderSideMenu(categories)}
              </SideMenu>
              <QuestionsContainer width={hideSideMenu ? 1 : 0}>
                <Switch>
                  <Route path='/wellness-assessment/health' render={this.medicalCategory}/>
                  <Route path='/wellness-assessment/lifestyle' render={this.lifeStyleCategory}/>
                  <Route path='/wellness-assessment/fitness' render={this.fitnessCategory}/>
                  <Route path='/wellness-assessment/nutrition' render={this.nutritionCategory}/>
                  <Route path='/wellness-assessment/stress' render={this.stressCategory}/>
                  <Route path='/wellness-assessment/workplace' render={this.workplaceCategory}/>
                  <Route path='/wellness-assessment/score' component={HraGraphResults} />
                  <Route
                    render={() =>
                      <WellbeingDashboard history={this.props.history}/>
                    }
                  />
                </Switch>
              </QuestionsContainer>
            </MainContainer>
          </FormContainer>
        </div>
      </Layout>
    )
  }
}

WellnessAssessment.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  wellnessAssessment: PropTypes.object,
  stressData: PropTypes.array,
  fetchHRAQuestionAnswers: PropTypes.func,
  hraQuestionsAnswersData: PropTypes.array,
  t: PropTypes.func
};

const mapStateToProps = (state) => ({
  wellnessAssessment: state.profileData.wellnessAssessment,
  stressData: state.wellBeingAssessment.stressData,
  hraQuestionsAnswersData: state.wellBeingAssessment.hraQuestionsAnswersData
});
const mapDispatchToProps = (dispatch) => ({
  getWellnessAssessment: () => dispatch(getWellnessAssessment()),
  fetchHRAQuestionAnswers: () => dispatch(fetchHRAQuestionAnswers()),
});
export default connect(mapStateToProps, mapDispatchToProps)((withTranslation())(WellnessAssessment));
