import styled from 'styled-components';

const Layout  = styled.div`
  width: 100%;
  padding: 20px 40px;
  margin-bottom: 80px;
  
  @media (max-width: 600px) {
    padding: 20px 10px;
  }
`;

const Title = styled.h1`
  padding: 15px 25px 15px 25px;
  background-color: #8ec227;
  color: #fff;
  margin: 0px 0px 60px 0px;
  font-size: 18px;
  display: inline-block;
  
  > img {
    margin-right: 10px;
    float: left;
  }
  
  > span {
    line-height: 20px;
  }
`;

const StyledLink = styled.a`
  background: #f58c1f;
  padding: 10px 20px;
  border: none;
  border-radius: 2px;
  text-shadow: none;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  position: relative;
  z-index: 1;
  
  &:hover, &:active, &:focus {
    text-decoration: none;
    color: #fff;
  }
`;

const FormContainer = styled.div`
  font-size: 14px;
  color: rgb(101, 104, 111);
`;

const StyledImage = styled.img`
  margin: -60px auto 0;
  display: block;
`;

export { Layout, Title, StyledImage, FormContainer, StyledLink };