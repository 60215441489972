import styled, {css}  from "styled-components";
const MediumFont = 'Rubik-Medium';
// const BoldFont = 'Rubik-Bold';
const RegularFont = 'Rubik-Regular';

const WellbeingDashboardContainer = styled.div`
 width: 100%;
 padding: 30px;
 background-color: #FFFFFF;
 float: left;
 box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.08);
 border-radius: 6px;
 margin-bottom: 20px;
 @media (max-width: 400px){
  padding: 0px;
 }
`;
const MainContainer = styled.div`
  width: 100%;
  padding: 25px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @media(max-width: 1165px){
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  @media(max-width: 500px){
    padding: 10px;
  }
`;
const HeaderTitle = styled.div`
  width: 100%;
  text-align: center;
  padding: 10px;
  > div: first-child{
    color: #67676A;
    font-family: ${MediumFont};
    font-size: 28px;
  }
  > div: last-child{
    color: #67676A;
    font-family: ${MediumFont};
    font-size: 14px;
    opacity: 0.5;
  }
  > p{
    text-align: center;
  }
`;
const SideMenu = styled.div`
  width: 30%;
  padding: 0 10px 0 0;
  @media (max-width: 1165px){
    width: 60%;
  }
  @media (max-width: 500px){
    width: 100%;
    padding: 10px;
  }
`;
const QuestionsContainer = styled.div`
  width: ${({width}) => width ? '100%' : 'calc(70% - 40px)'};
  margin: 0 20px;
  @media (max-width: 1165px){
    width: 100%;
    margin: 0 auto;
  }
  @media (max-width: 500px){
   width: 100%;
   padding: 10px;
  }
`;
const CategoriesContainer = styled.button`
  width: 100%;
  padding: 20px;
  background-color: #FFFFFF;
  border-radius: 6px;
  cursor: pointer;
  outline: none;
  border: none;
  margin-bottom: 4px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.08);
  > div{
    display: flex;
    align-items: center;
    justify-content: space-between;
    > div: first-child{
      display: flex;
      align-items: center;
      > span{
        color: #282C37;
        font-family: ${MediumFont};
        font-size: 14px;
        margin-left: 10px;
        text-transform: capitalize;
        @media(max-width: 340px){
          margin-left: 1px;
        }
      }
    }
    > div: last-child{
      font-family: ${RegularFont};
      color: #6D819C;
      font-size: 14px;
      text-transform: capitalize;
    }
  }
  ${({ disabled }) => disabled === 1 && css`
    cursor: not-allowed !important;
  `}
  ${({active}) => active === 1 && css`
    background-color: ${({bgColor}) => bgColor && bgColor};
    > div{
    > div: first-child{
      > span{
        color: #FFFFFF;
      }
    }
    > div: last-child{
      color: #FFFFFF;
    }
  }
  `}
`;
const RenderWellnessQuestions = styled.div`
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    float: left;
    margin: 0 0 30px 15px;
    align-items: center;
    padding: 0 10px;
    @media (max-width: 728px){
     padding: 0;
     margin: 0;
    }
`;
const RenderQuestionContainer = styled.div`
  padding: 45px;
  width: 100%;
  border-radius: 6px;
  background-color: #fff;
  margin-bottom: 10px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.08);
  @media(max-width: 670px){
    padding: 10px 20px;
  }
`;
const QuestionContainer = styled.div`
  padding-bottom: 15px;
  min-height: 'auto';
  >div {
    color: #484856;
    font-family: ${RegularFont};
    font-size: 19px;
    >span{
      color: #484856;
      font-family: ${RegularFont};
      font-size: 19px;
      font-style: italic;
    }
  }
  @media(max-width: 500px){
    min-height: 120px;
  }
`;

const OptionContainer = styled.div`
  border: 1px solid rgba(103, 103, 106, 0.5);
  box-sizing: border-box;
  border-radius: 6px;
  display: flex;
  align-items: center;
  padding: 15px;
  cursor: pointer;
  margin: 10px 0;
  > div: first-child{
    width: 20%;
    display: flex;
    justify-content: center;
  }
  > div: last-child{
    width: 80%;
    font-size: 15px;
    color: #67676A;
    font-family: ${RegularFont}
  }
  ${({ isActive }) => isActive && css`
    background-color: ${({bgColor}) => bgColor ? bgColor : '#C464AC'};
    color: #fff;
    border: none;
    > div: first-child {
      >div{
        display: flex;
      }
    }
    > div: last-child {
      color: #fff;
    }
  `}
`;

const CheckMark = styled.span`
  margin-bottom: 3px;
  &::after {
    content: "";
    display: flex;
    align-items: center;
    justify-content: center;
    width: 8px;
    height: 14px;
    border-style: solid;
    border-color: ${({bgColor}) => bgColor ? bgColor : '#C464AC'};
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    border-top-color: transparent;
    border-left-color: transparent;
  }
`;
const CheckMarkContainer = styled.div`
  display: none;
  width: 23px;
  height: 23px;
  border-radius: 50%;
  background-color: #fff;
  align-items: center;
  justify-content: center;
`;

const ButtonContainer = styled.div`
  width: 100%;
  float: left;
  text-align: right;
  @media (max-width: 500px){
    text-align: center;
  }
`;

const YourWellbeing = styled.div`
  width: 50%;
  float: left;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  @media (max-width: 750px){
    width: 100%;
    padding: 30px
  }
  > div: first-child{
    font-family: ${MediumFont};
    text-transform: uppercase;
    text-align: center;
  }
  > div{
    font-family: ${RegularFont};
    text-align: center;
    padding: 15px 30px;
    color: #26262B;
    font-size: 16px;
    opacity: 0.7;
  }
  > img{
    width: 200px;
    margin-top: 20px;
  }
  >p{
    font-family: ${RegularFont};
    text-align: center;
    padding: 15px 30px;
    margin-top: 5px;
  }
`;

const WellbeingCategories = styled.div`
  width: 50%;
  float: left;
  padding: 0 30px;
  text-align: center;
  @media (max-width: 750px){
    width: 100%;
    padding: 10px
  }
`;
const DetailsContainer = styled.div`
 width: 100%;
 padding: 0 30px;
  > div{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 3px solid #F6F6F8;
    margin: 20px 0;
    padding: 10px 0;
    margin: 10px 0;
    > div: first-child{
       display: flex;
       align-items: center;
      > div{
        width: 10px;
        height: 10px;
        background-color: ${({bgColor}) => bgColor && bgColor};
      }
      > span{
        color: #282C37;
        font-family: ${MediumFont};
        font-size: 14px;
        margin-left: 10px;
        text-transform: capitalize;
      }
    }
    > div: last-child{
      font-family: ${RegularFont};
      color: #6D819C;
      font-size: 14px;
      text-transform: capitalize;
    }
  }
`;
const TakeAssessmentButton = styled.button`
  width: 80%;
  height: 44px;
  border-radius: 34px;
  background-color: #E97573;
  border: none;
  font-size: 13px;
  color: #FFFFFF;
  font-family: ${MediumFont};
  text-align: center;
  text-transform: uppercase;
  padding: 0;
  margin-top: 10px;
  cursor: pointer;
  outline: none;
`;
const ImageFiller = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${({bgColor}) => bgColor && bgColor};
  display: flex;
  align-items: center;
  justify-content: center;
  >img{
    width: ${({width}) => width ? '12px' : '20px'};
  }
`;
export { WellbeingDashboardContainer, YourWellbeing, WellbeingCategories, MainContainer, SideMenu, HeaderTitle, QuestionsContainer, CategoriesContainer, QuestionContainer, RenderQuestionContainer, OptionContainer, CheckMark, CheckMarkContainer, RenderWellnessQuestions, ButtonContainer, DetailsContainer, TakeAssessmentButton, ImageFiller};